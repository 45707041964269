import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Emr)

export default {
  async search(keySearch = "", serviceID = "", typeID = "", statusID = 1, page = 1, limit = 10) {
    var query = {
      keySearch,
      serviceID,
      typeID,
      statusID,
    }
    var header = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit,
    }
    return httpClient.get(`/AxPx/Search`, query, header);
  },
  async searchAvailable(clinicID, keySearch = "", serviceID = "", typeID = "", statusID = 1, page = 1, limit = 10) {
    var query = {
      clinicID,
      keySearch,
      serviceID,
      typeID,
      statusID,
    }
    var header = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit,
    }
    return httpClient.get(`/AxPx/Available/Search`, query, header);
  },
  async getOrderItemDetail(targetID, typeID) {
    var body = {
      targetID,
      typeID,
    }
    return httpClient.post(`/ItemOrder/Details`, {}, body);
  },

  async getReferralOrderItemDetail(targetID, typeID, targetClinicID) {
    var body = {
      targetID,
      typeID,
      targetClinicID
    }
    return httpClient.post(`/ItemOrder/Referral/Details`, {}, body);
  }
}