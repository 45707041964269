import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Emr)

export default {
    async search(keySearch = "", statusID = "", page = 1, limit = 10) {
        var query = {
            keySearch,
            statusID,
        }
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit,
        }
        return httpClient.get(`/Service/Search`, query, headers);
    },
    async searchChildAxPx(serviceID) {
        var query = {
            serviceID
        }
        return httpClient.get(`/Service/Contents/{serviceID}`, query);
    },
    async searchAvailableByClicnicID(clinicID) {
        var query = {
            clinicID
        }
        return httpClient.get(`/Service/Search/{clinicID}`, query);
    }
}